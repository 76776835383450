export default {
	'form.details': 'sumNetAmount',
	'form.entry_date': 'setEntryDate',
	// 'payment_mode_type': 'paymentModeChange'
    'payment_mode_type.journal_type_id':function (v) {
        let d = this.pr_type.filter(data=> {
            if(data.value == v){
                return data
            }
        });

        if(d.length && d[0].setting == 'manual'){
            this.manualSetting = true;
        }else{
            if(v){
                this.$rest.get('/api/journal-type-voucher/'+v).then(data=> {
                    this.automaticVoucherNo = data.data
                });
            }
            this.manualSetting = false;
        }
    },
    searchA(val) {
        if (!val) return;
        // if (this.isLoading) return;

        this.isLoading = true;
        this.$rest.get('/api/account-head?search=' + val)
            .then(({data}) => {
                this.entriesA = data.data
            }).catch(err => {
        }).finally(() => (this.isLoading = false))
    },
    searchAG(val) {
        if (!val) return;
        // if (this.isLoading) return;

        this.isLoading = true;
        this.$rest.get('/api/account-head?search=' + val)
            .then(({data}) => {
                this.entriesA = data.data
            }).catch(err => {
        }).finally(() => (this.isLoading = false))
    },
    'payment_mode_type.ledger_head_id' : function (v) {
        this.paymentModeChange(v)

    }

}
